@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root {
  
  --primary: #014f7f;
  --light: #4bc0ff;
  --white: #fff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  font-family: 'Quicksand', sans-serif;
  line-height: 1.5;
  overflow-x: hidden;
}
