.legal {
  padding: 6rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 100vh;
}

@media screen and (max-width: 700px) {
  .legal {
    padding: 5rem 1rem 4rem;
  }
}
