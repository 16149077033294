// .navbar {
//   position: fixed;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   top: 0;
//   left: 0;
//   width: 100%;
//   padding: 0.5rem 2.5rem;
//   background-color: var(--white);
//   -webkit-box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//   -moz-box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//   box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//   z-index: 100;

//   .logo {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: auto;
//     height: 4rem;

//     img {
//       height: 4rem;
//       width: auto;
//     }
//   }

//   nav {
//     display: flex;
//     // flex-direction: column;

//     a {
//       display: flex;
//       // flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       // margin: 1rem 0;
//       // padding: 0.25rem;
//       margin: 0 0.5rem;
//       font-size: 0.9rem;
//       font-weight: 500;
//       // text-transform: uppercase;
//       border: 1px solid transparent;
//       border-radius: 0.5rem;
//       color: var(--primary);
//       transition: all 300ms ease;
//       text-align: center;

//       svg {
//         font-size: 1rem;
//         margin-right: 0.25rem;

//         path {
//           color: var(--primary);
//         }
//       }

      
//     }

//     hr {
//       border-top: 1px solid #c4c4c4;
//     }

//     .phone {
//       display: none;
//       text-align: center;
//     }
//   }
// }

// @media screen and (max-width: 700px) {
//   .navbar {
//     position: fixed;
//     display: flex;
//     // flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     top: 0;
//     left: 0;
//     // bottom: 2rem;
//     // width: 100%;
//     // padding: 1rem 2.5rem;
//     background-color: var(--white);
//     border-radius: 0.5rem;
//     -webkit-box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//     -moz-box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//     box-shadow: 10px 5px 24px 0px rgba(0, 0, 0, 0.41);
//     z-index: 100;

//     top: unset;
//     left: 50%;
//     right: unset;
//     bottom: 2rem;
//     transform: translate(-50%, 1rem);
//     width: fit-content;
//     height: 5rem;
//     flex-direction: row;
//     padding: 0 1rem;
//     justify-content: center;

//     .logo {
//       display: none;
//     }

//     nav {
//       display: flex;
//       // flex-direction: column;

//       a {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         // margin: 1rem 0;
//         padding: 0.25rem 0.5rem;
//         margin: 0 0.5rem;
//         font-size: 0.7rem;
//         font-weight: 500;
//         text-transform: uppercase;
//         border: 1px solid transparent;
//         border-radius: 0.5rem;
//         color: var(--primary);
//         transition: all 300ms ease;
//         text-align: center;

//         svg {
//           font-size: 1rem;
//           margin-right: 0;
//           margin-bottom: 0.25rem;

//           path {
//             color: var(--primary);
//           }
//         }

        

      
//       }
//       .active {
//         background-color: var(--primary);
//         color: var(--white);

//         svg {
//           path {
//             color: var(--white);
//           }
//         }
//       }

//       .phone {
//         display: flex;
//       }
//     }
//   }
  
// }
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 2.5rem;
  background-color: var(--white);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); // Slight shadow for the navbar
  z-index: 100; // Navbar stays on top of the dropdown
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 4rem;
      width: auto;
    }
  }

  .hamburger {
    display: flex;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 110; // Keeps the button above the dropdown menu

    svg{
      color: var(--primary);
    }
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; // Start just below the navbar
    left: 0;
    width: 100%; // Full-width dropdown
    height: 0; // Initial height is 0 to hide the menu
    overflow: hidden; // Prevent content from showing during animation
    background-color: var(--white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); // Matches navbar shadow
    transition: height 0.5s ease; // Smooth expand/collapse animation
    z-index: 90; // Stays below the navbar

    opacity: 1; // Ensure menu is visible when opened

    a {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;
      font-size: 1rem;
      font-weight: 500;
      text-decoration: none;
      color: var(--primary);
      padding: 0.5rem 1rem;

      svg {
        margin-right: 0.5rem;
      }

      &:hover {
        color: var(--secondary);
      }
    }

    
  }

  .nav-menu.open {
    height: auto; // Dropdown expands to fit its content
    max-height: 500px; // Optional: Prevents dropdown from growing too large
  }
}

@media screen and (min-width: 700px) {
  .nav-menu {
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .nav-menu {
    text-align: center;

   
  }
}