.bookingPage {
  width: 100%;
  padding: 6rem 0 4rem;
  display: flex;
  gap: 2rem;
  min-height: calc(100vh - 20rem);

  .bookingPageContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    width: 34.5rem;
    padding: 1rem;
    gap: 2rem;

    textarea {
      width: 100%;
      border: 1px solid rgb(201, 201, 201);
      font: inherit;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      resize: none;
    }

    input {
      width: 100%;
      border: 1px solid rgb(201, 201, 201);
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: var(--primary);
      color: var(--white);
      padding: 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      transition: all 300ms ease;
    }

    button:disabled,
    button[disabled] {
      background-color: #dadada;
      cursor: not-allowed;
    }

    .datePickerClosed {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      gap: 0.5rem;
    }

    .datePickerOpen {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(5, 1fr);
      gap: 0.5rem;
    }

    .datePickerContainer {
      background-color: rgb(242, 242, 242);
      height: 5rem;
      width: 5rem;
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      justify-content: center;
      align-items: center;
      transition: all 300ms ease;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 2px solid transparent;

      .datePickerContainer-number {
        font-size: 1.25rem;
        font-weight: 600;
      }

      &:hover {
        background-color: aliceblue;
        border-color: var(--primary);
      }
    }

    .timePicker {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;

      .timeSlot {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 2.25rem;
        border-radius: 0.5rem;
        background-color: rgb(242, 242, 242);
        cursor: pointer;
        border: 2px solid transparent;
        font-weight: 600;
        transition: all 300ms ease;

        &:hover {
          background-color: aliceblue;
          border-color: var(--primary);
        }
      }
    }
  }
}

.selected {
  background-color: var(--primary) !important;
  color: white;
}

.disabled {
  background-color: rgb(174, 174, 174) !important;

  color: white;
  cursor: default !important;

  &:hover {
    border-color: rgb(174, 174, 174) !important;
  }
}

@media screen and (max-width: 1100px) {
  .bookingPage {
    flex-direction: column;
    gap: 0;
  }
}

@media screen and (max-width: 700px) {
  .bookingPage {
    padding: 2rem;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .bookingPage {
    padding: 2rem 1rem;
    .bookingPageContainer {
      align-items: center;
      max-width: 100%;

      .datePickerClosed {
        width: fit-content;
        grid-template-columns: repeat(4, 1fr);
      }
      .datePickerOpen {
        width: fit-content;
        grid-template-columns: repeat(4, 1fr);
      }

      .timePicker {
        grid-template-columns: repeat(2, 1fr);
        max-width: 21.5rem;
        justify-items: center;

        .timeSlot {
          padding: 0.75rem 1.5rem;
          width: 10rem;
        }
      }

      textarea,
      input,
      button {
        max-width: 21.5rem;
      }
    }
  }
}
