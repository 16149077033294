.faqPage {
  padding: 5rem 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .questions {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .faqPageHeader {
    width: 100%;
    height: 80vh;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // border-radius: 0.5rem;
    }

    .imageOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      // border-radius: 0.5rem;
    }

     .imageText {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 4rem;
      gap: 1rem;

      h2 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-size: 2rem;
        color: var(--white);

        span {
          color: var(--primary);
        }
      }

      .text {
        font-size: 2rem;
        // border-left: 2px solid var(--primary);
        // padding-left: 3rem;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 2rem;
        width: 60%;
        color: var(--white);

        span {
          color: var(--primary);
          font-weight: 700;
        }
      }

      a {
        padding: 0.75rem 1.5rem;
        background-color: var(--primary);
        width: fit-content;
        color: var(--white);
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        font-weight: 600;

        svg {
          font-size: 1.25rem;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .faqPage {
    padding: 5rem 0 4rem;
    gap: 2rem;

    .questions {
      padding: 0 1rem;
    }

    .faqPageHeader {
      height: 40vh;

      .imageText {
        padding-left: 2rem;

         h2 {
          font-size: 1.25rem;
        }

        .text {
          font-size: 1rem;
          width: 90%;
          // padding-left: 1.5rem;
        }
      }
    }
  }
}