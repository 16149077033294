.homePage {
  padding: 5rem 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .headerImage {
    width: 100%;
    height: 80vh;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      // border-radius: 0.5rem;
    }

    .imageOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      // border-radius: 0.5rem;
    }

    .imageText {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 4rem;
      gap: 1rem;

      h2 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-size: 2rem;
        color: var(--white);

        span {
          color: var(--light);
        }
      }

      .text {
        font-size: 2rem;
        // border-left: 2px solid var(--primary);
        // padding-left: 3rem;
        line-height: 1.4;
        font-weight: 400;
        margin-bottom: 2rem;
        width: 60%;
        color: var(--white);

        span {
          color: var(--light);
          font-weight: 700;
        }
      }

      a {
        padding: 0.75rem 1.5rem;
        background-color: var(--primary);
        width: fit-content;

        color: var(--white);
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        font-weight: 600;

        svg {
          font-size: 1.25rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .homePageAbout {
    display: flex;
    flex-direction: column;
    padding: 0 10%;

    h2 {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 2rem;
      color: var(--primary);
    }

    .homePageAbout__text {
      font-size: 2rem;
      // border-left: 2px solid var(--primary);
      // padding-left: 3rem;
      line-height: 1.4;
      font-weight: 300;
      margin-bottom: 2rem;

      span {
        color: var(--primary);
        font-weight: 700;
      }
    }

    h3 {
      font-weight: 300;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        path {
          color: var(--primary);
        }
      }
    }
  }

  .homePageServices {
    position: relative;
    display: flex;
    align-items: center;

    .servicesSwiper {
      flex: 2;
      display: flex;
      overflow: hidden;

      .imageSwiper {
        display: flex;
        gap: 2rem;

        .image {
          // width: 23rem;
          height: 34rem;
          position: relative;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            // border-radius: 0.5rem;
          }

          .imageOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // border-radius: 0.5rem;
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .servicesContent {
      display: flex;
      flex-direction: column;
      flex: 4;
      gap: 2rem;
      padding: 2rem;

      p {
        padding-left: 4rem;
        font-size: 1.15rem;
      }

      h2 {
        margin-bottom: 1rem;
        font-weight: 700;
        color: var(--primary);
        // text-transform: uppercase;
      }

      ul{
        display: flex;
        flex-direction: column;
       
        gap: 0.5rem;

        li{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          width: 100%;
          // font-size: 1.15rem;

          span{
            font-weight: 700;
            color: var(--primary);
          }
        }
      }
    }
  }

  .homePageWhyUs {
    display: flex;

    .whyUsContent {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;

      h2 {
        margin-bottom: 1rem;
        font-weight: 500;
        color: var(--primary);
        text-transform: uppercase;
      }

      p {
        width: 85%;
        font-size: 1.15rem;
      }
    }

    .whyUsImage {
      flex: 4;
      height: 34rem;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        // border-radius: 0.5rem;
      }
    }
  }

  section {
    width: 100%;
    padding: 2rem 0;
    display: flex;

    justify-content: center;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;

      svg {
        font-size: 2.5rem;
        margin-bottom: 1rem;

        path {
          color: var(--primary);
        }
      }

      h3 {
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .homePage {
    .headerImage {
      .imageText {
        .text {
          width: 80%;
        }
      }
    }

    .homePageAbout {
      padding: 0 7%;

      h2 {
        font-size: 1.25rem;
      }
      .homePageAbout__text {
        font-size: 1.25rem;
      }

      h3 {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .homePage {
    .headerImage {
      .imageText {
        padding-left: 2rem;

        h2 {
          font-size: 1.75rem;
        }

        .text {
          font-size: 1.5rem;
          // padding-left: 1.5rem;
        }
      }
    }

    section {
      .column {
        svg {
          font-size: 2rem;
        }

        h3 {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .homePage {
    padding: 5rem 0 4rem;
    gap: 2rem;

    .headerImage {
      height: 60vh;

      .imageText {
        h2 {
          font-size: 1.5rem;
        }

        a {
          display: none;
        }
        .text {
          font-size: 1.25rem;
          width: 90%;
        }
      }
    }

    .homePageServices {
      flex-direction: column;
      gap: 2rem;

      .servicesSwiper {
        width: 100%;
        justify-content: center;

        .imageSwiper {
          gap: 1rem;

          .image {
            width: auto;
            height: 15rem;
            flex: 1;
          }
        }
      }

      .servicesContent {
        p {
          padding-left: 0;
        }
      }
    }

    .homePageWhyUs {
      flex-direction: column;
      gap: 2rem;

      .whyUsImage {
        height: 20rem;
        flex: unset;
      }

      .whyUsContent {
        p{
          width: 100%;
        }
        h2 {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .homePage {
    .headerImage {
      height: 40vh;

      .imageText {
        h2 {
          font-size: 1.5rem;
          line-height: 1.2;
        }

        .text {
          font-size: 1.15rem;
        }
      }
    }

    .homePageAbout {
      padding: 0 2rem;

      h2 {
        font-size: 1.15rem;
        margin-bottom: 1rem;
      }

      .homePageAbout__text {
        font-size: 1.15rem;
        // padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
    }

    section {
      flex-direction: column;
      gap: 2rem;

      .column {
        svg {
          font-size: 2rem;
        }

        h3 {
          font-size: 1rem;
        }
      }
    }
  }
}
