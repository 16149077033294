.successPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;

  h2 {
    width: 50%;
    font-size: 2.5rem;
    color: var(--primary);

  }

  p {
    width: 50%;

  }

  a {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 0.5rem;
    width: fit-content;
    padding: 0.5rem 2.5rem;
  }
}

@media screen and (max-width: 700px) {
  .successPage {
    h2 {
      width: 90%;
    }
    p{
      width: 90%;
    }
  }
}

@media screen and (max-width: 500px) {
  .successPage {
    h2 {
      font-size: 1.75rem;
    }
  }
}