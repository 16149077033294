footer {
  width: 100%;
  height: fit-content;
  background-color: rgb(239, 246, 250);
  display: flex;
  align-items: center;
  padding: 2rem 10rem 2rem 2rem;
  gap: 4rem;

  .logo {
    height: 15rem;

    img {
      height: 100%;
      object-fit: cover;
      mix-blend-mode: multiply;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;

    a {
      color: var(--primary);
      margin: 1rem 0;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 800;

      svg {
        font-size: 2rem;
        margin-right: 1rem;

        path {
          color: var(--primary);
        }
      }
    }
  }

  .links,
  .social {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;

    a {
      color: var(--primary);

      svg {
        font-size: 2rem;

        path {
          color: var(--primary);
        }
      }
    }
  }

  .social {
    flex-direction: row;
    gap: 2rem;
  }
}
@media screen and (max-width: 1250px) {
  footer {
    justify-content: center;
    gap: 2rem;

    .contact {
      a {
        font-size: 1rem;

        svg {
          font-size: 1.5rem;
        }
      }
    }

    .links {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  footer {
    flex-direction: column;
    padding: 2rem;
  }
}

@media screen and (max-width: 700px) {
  footer {
    padding: 2rem 2rem 8rem;
  }
}
